import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './home-page/home-page.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {LoginComponent, LogoutComponent, OauthCallbackComponent, OtaAuthGuardService} from '@cvpp-ota/security';
import {ApsErrorComponent} from './aps-error/aps-error.component';

const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'home', component: HomePageComponent, canActivate: [OtaAuthGuardService]},
    {path: 'home-page', redirectTo: '/home', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'oauth-callback', component: OauthCallbackComponent},
    {path: 'apsError/:message', component: ApsErrorComponent},
    {path: '404', component: PageNotFoundComponent},
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
