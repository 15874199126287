import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VideoLink} from '../Models/video-link';

@Component({
    selector: 'app-individual-videos',
    templateUrl: './individual-video.component.html',
    styleUrls: ['./individual-video.component.scss']
})
export class IndividualVideoComponent implements OnInit {

    @Input() videoLink: VideoLink;
    myID: string;
    static id = 0;

    ngOnInit() {
        IndividualVideoComponent.id++;
        this.myID = '' + IndividualVideoComponent.id;
    }

    @ViewChild('videoPlayer', {static: true}) videoPlayer: ElementRef;

    playVideo() {
        const video = document.getElementById(this.myID);
        const overlay = document.getElementById('div' + this.myID);
        video.setAttribute('controls', '');
        overlay.setAttribute('style', 'display: none');
        video.setAttribute('autoplay', '');
        this.videoPlayer.nativeElement.play();
    }
}
