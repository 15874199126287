import {Component, Input} from '@angular/core';
import {CardHotLink} from '../model/card-hot-link';
import {OtaMenuItem} from '@cvpp-ota/menu/lib/navigation/models/ota-menu-item';
import {OtaApsService, OtaSecurityConfigService} from '@cvpp-ota/security';

@Component({
    selector: 'app-card-shortcuts',
    templateUrl: './card-shortcuts.component.html',
    styleUrls: ['./card-shortcuts.component.scss']
})
export class CardShortcutsComponent {

    @Input() hotLink: CardHotLink;

    constructor(private _security: OtaSecurityConfigService,
                private _apsService: OtaApsService) {
    }

    isDisabled(link: OtaMenuItem): boolean {
        return this._apsService.isNotAccessible(link.security);
    }
}
