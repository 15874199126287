import {Component, OnInit} from '@angular/core';
import {VideoLink} from './Models/video-link';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-training-videos',
    templateUrl: './training-videos.component.html',
    styleUrls: ['./training-videos.component.scss']
})
export class TrainingVideosComponent implements OnInit {

    private videos: Array<VideoLink> = [];

    ngOnInit() {
        environment.videoLinks.forEach(video => this.videos.push(video));
    }

    get videoArray(): Array<VideoLink> {
        return this.videos;
    }

}
